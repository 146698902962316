<template>
    <div class="">
        <div class="mx-auto flex gap-4">


            <div class="" id="democall">

                <div class="relative">
                    <p class="text-2xl mb-4 font-bold text-center pb-8">
                        Try it out! Speak with John, our Demo AI.
                    </p>

                    <img src="@/../images/arrows/aroow-15.png?format=webp&w=100" alt=""
                         class="-scale-50 -rotate-12 lg:rotate-0 -my-4 absolute top-12 lg:top-5 right-10 " style=""/>
                </div>

                <div class="relative max-w-[600px] shadow-md rounded-xl overflow-hidden">
                    <div class="bg-secondary p-4 text-white">
                        <div class="flex gap-4 items-center">
                            <img :src="face" class="w-12 rounded-full shrink-0 aspect-square grow-0"/>

                            <p class="">
                                Speak with John, Greetmate's AI.
                                <br class="hidden xl:block">
                                <span class="hidden xl:inline">
                                <AppBadge class="bg-primary text-white mr-1">
                                    Available
                                </AppBadge>
                                <AppBadge class="bg-primary text-white">
                                    English
                                </AppBadge>
                                    </span>
                            </p>
                        </div>
                    </div>
                    <form
                        v-if="!submitted"
                        @submit.prevent="submit"
                        method="post" class="space-y-4 bg-gray-50 p-4 rounded-b-xl border border-1 border-secondary">

                        <div>
                            <InputLabel for="Name" value="Name"/>
                            <TextInput
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="mt-1 block w-full"
                                autocomplete="phone"
                                placeholder="Your Name"
                            />
                            <InputError :message="form.errors.name" class="mt-2"/>
                        </div>
                        <!--                <div>-->
                        <!--                    <InputLabel for="Email" value="Email"/>-->
                        <!--                    <TextInput-->
                        <!--                        id="Email"-->
                        <!--                        v-model="form.email"-->
                        <!--                        type="text"-->
                        <!--                        class="mt-1 block w-full"-->
                        <!--                        autocomplete="phone"-->
                        <!--                        placeholder="Email Address"-->
                        <!--                    />-->
                        <!--                    <InputError :message="form.errors.email" class="mt-2"/>-->
                        <!--                </div>-->
                        <div>
                            <InputLabel for="phone" value="Phone Number" class="mb-1"/>
                            <AppPhoneField
                                id="phone"
                                v-model="form.number"
                            />
                            <InputError :message="form.errors.number" class="mt-2"/>
                        </div>

                        <!--                <InputLabel for="terms" class="cursor-pointer">-->
                        <!--                    <div class="flex items-center">-->
                        <!--                        <Checkbox id="terms" v-model:checked="form.terms" name="terms" required />-->

                        <!--                        <div class="ms-2">-->
                        <!--                            I agree to the <a target="_blank" :href="route('terms.show')" class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">Terms of Service</a> and <a target="_blank" :href="route('policy.show')" class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">Privacy Policy</a>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <!--                    <InputError class="mt-2" :message="form.errors.terms" />-->
                        <!--                </InputLabel>-->

                        <div class="flex flex-col max-lg:flex-col gap-4 items-center">
                            <!--                        <InputLabel for="marketing" class="cursor-pointer">-->
                            <!--                            <div class="flex items-center">-->
                            <!--                                <Checkbox id="marketing" v-model:checked="form.marketing" name="marketing"/>-->

                            <!--                                <div class="ms-2">-->
                            <!--                                    I agree to receive marketing communications from Greetmate via phone and email.-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                            <InputError class="mt-2" :message="form.errors.marketing"/>-->
                            <!--                        </InputLabel>-->

                            <p class="text-[9px]">
                                By submitting your phone number, you consent to being contacted. You also agree to the
                                use of your contact
                                information for marketing purposes, in accordance with our Privacy Policy. You may opt
                                out
                                at any time.
                            </p>

                            <div class="text-right">
                                <PrimaryButton
                                    @click="submit"
                                    :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                    Call Me Now
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                    <div v-else class="bg-white py-16 mt-6 rounded-lg">
                        <div class="text-center">
                            <p class="text-lg">
                                Thank you for your interest!
                            </p>
                            <p class="text-sm">
                                We will call you shortly.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
// @ts-nocheck
import FacePlayer from "@/Components/Marketing/FacePlayer.vue";
import AppBadge from "@/Components/AppBadge.vue";
import face from '../../../images/voices/mark.png?format=webp&h=400';
// import ana from '../../../images/voices/ana.png?format=webp&h=400';

import {useForm} from "@inertiajs/vue3";
import InputLabel from "@/Components/InputLabel.vue";
import InputError from "@/Components/InputError.vue";
import AppPhoneField from "@/Components/AppPhoneField.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
//import {route} from "ziggy-js";
import {onMounted, ref} from "vue";
import FaceRecordingPlayer from "@/Components/Marketing/FaceRecordingPlayer.vue";
import TextInput from "@/Components/TextInput.vue";

onMounted(() => {
    if (window.location.href.includes('demoCallForm')) {
        submitted.value = true
    }
})

const form = useForm({
    name: '',
    email: '',
    number: '',
    terms: false,
    marketing: false,
})

const submitted = ref(false)
const submit = () => {

    form.post(route('audio-example.test-call'), {
        preserveScroll: true,
        onSuccess: () => {
            try {
                // @ts-ignore
                fbq('trackCustom', 'DemoCall')

                // @ts-ignore
                window.gtag('event', 'conversion_event_phone_call_lead', {
                    'event_callback': () => {
                        window.location.href = '?demoCallForm=true'
                        submitted.value = true
                    },
                    'event_timeout': 2000,
                });

            } catch (e) {
                console.log(e);
            }
        }
    })
}
</script>
